
/* video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  top: 0
}
 */
.hero-research {
  background: url('../img/research-hero.jpg') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  z-index: -1;
}

.container-text > h1 {
  color: #fff;
  font-size: 96px;
  margin-top: -100px;
}

.container-text > p {
  margin-top: 8px;
  margin-bottom: 8px;
  color: white;
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
   /*  text-shadow: 0.1em 0.1em 0.2em black */
    

}

.hero-btns {
  margin-top: 32px;
  display: flex;
}

#animation {
  color: #08a8e9;
}

.container > h1{
  color: white;
  text-shadow: 0.1em 0.1em 0.2em black

}


 


/*////////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media screen and (max-width: 768px) {
  .hero-container {
    height: 120vh;
  }

  .container-text > h1 {
    font-size: 50px;
  }

  .container-text > p {
    font-size: 18px;
  }
}
  

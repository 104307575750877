.footer {
    font-size: 16px;
  }
  
  .footer .footer-content {
    background-color: rgb(127, 193, 247) ;
    background-size: contain;
    padding: 60px 0 30px 0;
  }
  
  .footer .footer-content .footer-info {
    margin-bottom: 30px;
  }
  
  .footer .footer-content .footer-info .logo {
    line-height: 0;
    margin-bottom: 25px;
  }
  
  .footer .footer-content .footer-info .logo img {
    max-height: 80px;
    margin-right: 6px;
  }
  
  .footer .footer-content .footer-info .logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: var(--color-secondary);
    font-family: var(--font-secondary);
    margin-top: 3px;
  }
  
  .footer .footer-content .footer-info p {
    font-size: 16px;
    margin-bottom: 0;
    font-family: var(--font-primary);
    color: var(--color-secondary); 
  }
  
  .footer .footer-content .social-links a {
    font-size: 14px;
    line-height: 0;
    display: inline-block;
    width: 32px;
    height: 32px;
    color: rgba(0, 3, 7, 0.911);
    margin-right: 10px;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    color: rgba(43, 57, 141, 0.6);
    border-radius: 50px;
  }
  
  .footer .footer-content .social-links a:hover {
    color: #fff;
  }
  
  .footer .footer-content h4 {
    font-size: 16px;
    font-weight: bold;
    color: #00050a;
    position: relative;
    padding-bottom: 12px;
  }
  
  .footer .footer-content .footer-links {
    margin-bottom: 30px;
  }
  
  .footer .footer-content .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer .footer-content .footer-links ul i {
    margin-right: 2px;
    color: var(--color-primary);
    font-size: 16px;
    line-height: 0;
  }
  
  .footer .footer-content .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .footer .footer-content .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  .footer .footer-content .footer-links ul a {
    color: #01060c;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  .footer .footer-content .footer-links ul a:hover {
    color: var(--color-primary);
  }
  
  .footer .footer-content .footer-contact p {
    line-height: 26px;
    color: #000913;
  }
  
  .footer .footer-legal {
    background: rgb(127, 193, 247) ;
    padding: 30px 0;
  }
  
  .footer .footer-legal .copyright {
    text-align: center;
    color: #000102;
  }
  
  .footer .footer-legal .credits {
    padding-top: 4px;
    text-align: center;
    font-size: 13px;
    color: var(--color-secondary);
  }
  
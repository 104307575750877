@media screen and (max-width: 768px) {
    .hero-container {
      height: 100vh;
    }
  
    .container-text > h1 {
      font-size: 50px;
    }
  
    .container-text > p {
      font-size: 18px;
    }
  }
    
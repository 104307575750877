/*--------------------------------------------------------------
# Services Cards Section
--------------------------------------------------------------*/
.services-cards {
  background: #f7f9fc;
}

.services-cards .card-item {
  border: 1px solid rgba(27, 47, 69, 0.1);
  background: #fff;
  position: relative;
  border-radius: 0;
}

.services-cards .card-item .card-bg {
  min-height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
}

.services-cards .card-item .card-body {
  padding: 30px;
}

.services-cards .card-item h4 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--color-secondary);
}

.services-cards .card-item p {
  color: var(--color-secondary);
  margin: 0;
}

.img-center{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
}

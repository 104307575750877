.nav-color{
    background-color: #2b3a8c;
}


/* @media only screen and (max-width: 992px) {          
    .nav-link{         
    color: black;
    margin: 0 10px;
    
      font-weight: 500;        
} } */
